*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    display: flex;
    flex-direction: column;
}

body {
    font-family: 'Karla', sans-serif;
    color: #1e1e1e;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    letter-spacing: -0.2px;
}

h1, h2, h3, h4 {
    font-family: 'Maven Pro', sans-serif;
    color: #1e1e1e;
    letter-spacing: -0.3px;
    font-weight: 800;
}

h1 {
    font-size: 50px;
}

h2 {
    font-size: 42px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Style date picker header */
span .MuiPickersToolbarText-toolbarBtnSelected {
    color: white;
}

/* Style date picker header */
span .MuiPickersToolbarText-toolbarTxt {
    color: white;
}

button.MuiPickersDay-daySelected {
    color: white;
}

.ql-editor {
    min-height:200px;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-size-huge {
    font-size: 2.5em;
}

.ql-size-large {
    font-size: 1.5em;
}

.ql-size-small {
    font-size: 0.75em;
}

.news-table ul,
.news-table ol,
.MuiExpansionPanelDetails-root ul,
.MuiExpansionPanelDetails-root ol {
    padding: 0 0 0 40px;
}

/* maven-pro-regular - latin */
@font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/maven-pro-v32-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-regular.svg#MavenPro') format('svg'); /* Legacy iOS */
  }
  /* maven-pro-500 - latin */
  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/maven-pro-v32-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-500.svg#MavenPro') format('svg'); /* Legacy iOS */
  }
  /* maven-pro-600 - latin */
  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/maven-pro-v32-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-600.svg#MavenPro') format('svg'); /* Legacy iOS */
  }
  /* maven-pro-700 - latin */
  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/maven-pro-v32-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-700.svg#MavenPro') format('svg'); /* Legacy iOS */
  }
  /* maven-pro-800 - latin */
  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/maven-pro-v32-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-800.svg#MavenPro') format('svg'); /* Legacy iOS */
  }
  /* maven-pro-900 - latin */
  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/maven-pro-v32-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/maven-pro-v32-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/maven-pro-v32-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/maven-pro-v32-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/maven-pro-v32-latin-900.svg#MavenPro') format('svg'); /* Legacy iOS */
  }

/* karla-regular - latin */
@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/karla-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-600 - latin */
  @font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/karla-v23-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-600.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-700 - latin */
  @font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/karla-v23-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-700.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-800 - latin */
  @font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/karla-v23-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-800.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-700italic - latin */
  @font-face {
    font-family: 'Karla';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/karla-v23-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-700italic.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-600italic - latin */
  @font-face {
    font-family: 'Karla';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/karla-v23-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-600italic.svg#Karla') format('svg'); /* Legacy iOS */
  }
  /* karla-800italic - latin */
  @font-face {
    font-family: 'Karla';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/karla-v23-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/karla-v23-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/karla-v23-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/karla-v23-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/karla-v23-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/karla-v23-latin-800italic.svg#Karla') format('svg'); /* Legacy iOS */
  }